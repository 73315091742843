import React from "react";
import { Card } from "@mui/material";
import { Icon } from "@iconify/react";
import moment from "moment";
import { convertCurrencyToSign } from "src/utils/constants";
import { htmlDecode } from "src/utils/convertHtml";

function TransactionCard({ transaction, handlePage }) {
  return (
    <Card className="payment-card mb-3">
      <div className="row p-3">
        <div className="col-12">
          <div className="mb-3">
            {transaction.payment_request ? (
              <>
                <p>Transaction Added From Payment Request</p>
                <p style={{ color: "#C19D60", fontSize: "18px" }}>
                  {htmlDecode(transaction.payment_request.request_title)}
                </p>
              </>
            ) : (
              "Transaction Added From Website"
            )}
          </div>
          <div className="mb-3 svg-color date-color">
            <Icon icon="uiw:date" />
            <span>
              &nbsp;{moment(transaction.createdAt).format("MMM DD YYYY")}
            </span>
          </div>
          <div className="row">
            <div className="col-8 col-md-6 col-lg-3 ">Transaction Type:</div>
            <div className="col-4 col-md-6 col-lg-3 text-muted">
              {transaction.transaction_request_type === "onetime"
                ? "OneTime"
                : "Recurring"}
            </div>
            <div className="col-8 col-md-6 col-lg-3 mt-3 mt-lg-0">
              Tax Percentage:
            </div>
            <div className="col-4 col-md-6 col-lg-3  mt-3 mt-lg-0 text-muted">
              {transaction.tax_percentage.toLocaleString()}%
            </div>
            <div className="col-8 col-md-6 col-lg-3 mt-3">Tax Amount:</div>
            <div className="col-4 col-md-6 col-lg-3 text-muted mt-3">
              {convertCurrencyToSign(transaction.currency)}
              {transaction.tax_amount.toLocaleString()}
            </div>
            <div className="col-8 col-md-6 col-lg-3 mt-3">Sub Amount:</div>
            <div className="col-4 col-md-6 col-lg-3  mt-3 text-muted">
              {convertCurrencyToSign(transaction.currency)}
              {transaction.sub_amount.toLocaleString()}
            </div>
            <div className="col-8 col-md-6 col-lg-3 mt-3">Total Amount:</div>
            <div className="col-4 col-md-6 col-lg-3  mt-3 text-muted">
              {convertCurrencyToSign(transaction.currency)}
              {transaction.amount.toLocaleString()}
            </div>

            {handlePage && transaction?.payment_request && (
              <div className="col-12 text-center">
                <button
                  className="small-contained-button mt-4"
                  onClick={() => {
                    handlePage(
                      transaction.payment_request.payment_request_slug
                    );
                  }}>
                  View Request
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TransactionCard;
