import { CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { live_event_new_detail_api } from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatCard from "src/components/Chat/ChatCard";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function PortalDetail() {
  const [eventDetail, setEventDetail] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleDate = (object) => {
    const originalDate = new Date(object.start_date);
    const newDate = new Date(originalDate);
    newDate.setDate(originalDate.getDate() + object.no_of_days);
    const newDateString = newDate.toISOString().split("T")[0];
    return newDateString;
  };
  const getLiveEventsDetail = async () => {
    const result = await live_event_new_detail_api(params?.event_slug);
    if (result.code === 200) {
      setEventDetail(result.member_dynamite_event);
      if (result.member_dynamite_event?.is_chat_enabled) {
        setShowChat(true);
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state) {
      console.log(state, "state");
      setEventDetail(state);
      if (state?.is_chat_enabled) {
        setShowChat(true);
      }
      setIsLoading(false);
    } else {
      getLiveEventsDetail();
    }
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container-fluid ps-0 ps-lg-5 mt-md-5">
      <div className="row display-flex">
        <div className="col-12 d-flex">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/portals")}>
            <ArrowBackIcon />
          </IconButton>
          <h2 className="ms-2">{eventDetail.title}</h2>
        </div>

        <div className="col-12">
          <p className="date-portals d-flex">
            <span className="date-portals-title">Event Date:</span>
            <span>
              {" "}
              {eventDetail.client_start_date} to {eventDetail.client_end_date}
              {/* {eventDetail.end_date
                ? eventDetail.end_date
                : handleDate(eventDetail)} */}
            </span>
          </p>
        </div>
      </div>

      <div className="row align-items-center">
        <div className={showChat ? "col-12 col-lg-8" : "col-12"}>
          {eventDetail.video_url ? (
            <div
              className="video-container"
              dangerouslySetInnerHTML={{
                __html: eventDetail.video_url,
              }}></div>
          ) : (
            <img src={s3baseUrl + eventDetail.images?.thumbnail_1}></img>
          )}
        </div>
        {showChat && (
          <ChatCard eventDetail={eventDetail} setEventDetail={setEventDetail} />
        )}

        <div className="col-12 mt-3">
          <div
            dangerouslySetInnerHTML={{
              __html: eventDetail?.detail_description,
            }}></div>
        </div>
      </div>
    </div>
  );
}
