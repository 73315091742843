import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import KimResponce from "./KimResponce";
import CustomDrawer from "../FormsDrewer/CustomDrawer";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ownerName } from "../../config/config";

import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import {
  AddAllAnswersApi,
  AddSingleAnswerApi,
} from "src/DAL/SelfImage/SelfImage";
const useStyles = makeStyles((theme) => ({
  dense: {
    marginTop: theme.spacing(0),
  },
}));

const SelfImageMessage = ({ replies, data }) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [responceDrawer, setResponceDrawer] = useState(false);
  const [repliesDrawer, setRepliesDrawer] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [questionMessages, setQuestionMessages] = useState([]);
  const [comments, setComments] = useState([]);
  const [question, setQuestion] = useState("");
  const [isAllAnswered, setIsAllAnswered] = useState(false);

  const [inputs, setInputs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const toggleInput = () => {
    setToggle(!toggle);
  };
  const handleKimResponce = (question, comments) => {
    setResponceDrawer(true);
    setComments(comments);
    setQuestion(question);
  };
  const handleKimReplies = () => {
    setRepliesDrawer(true);
  };
  const handleChange = (index, event) => {
    let value = event.target.value;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.answer = value;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const startLoading = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = true;
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const hideLoading = (index) => {
    const length = questionMessages.length - 1;
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    temp_element.isLoading = false;
    temp_element.isExpanded = false;
    temp_state[index] = temp_element;
    if (index !== length) {
      let temp_nex_element = { ...temp_state[index + 1] };
      temp_nex_element.isExpanded = true;
      temp_state[index + 1] = temp_nex_element;
    }
    setQuestionMessages(temp_state);
  };

  const handleOpenDrawer = () => {
    setResponceDrawer(true);
  };

  const handleCloseDrawer = () => {
    setResponceDrawer(false);
  };

  const handleOpenRepliesDrawer = () => {
    setRepliesDrawer(true);
  };

  const handleCloseRepliesDrawer = () => {
    setRepliesDrawer(false);
  };

  const handleSubmitReply = () => {};

  const expandArea = (index) => {
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    if (temp_element.isExpanded === false) {
      temp_element.isExpanded = true;
    } else {
      temp_element.isExpanded = false;
    }
    temp_state[index] = temp_element;
    setQuestionMessages(temp_state);
  };

  const handleSubmit = async (e, index) => {
    e.preventDefault();
    startLoading(index);
    let temp_state = [...questionMessages];
    let temp_element = { ...temp_state[index] };
    checkIfAllAnswered(questionMessages);

    const formDataObject = {
      question_id: temp_element._id,
      answer: temp_element.answer,
      self_image_completed_status: isAllAnswered,
      module_type: "self_image",
    };

    const result = await AddSingleAnswerApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      hideLoading(index);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      hideLoading(index);
    }
  };

  const handleSubmitAll = async () => {
    setIsLoadingAll(true);
    let answersArray = [];
    let answersObject = {};
    checkIfAllAnswered(questionMessages);

    questionMessages.map((answers, index) => {
      answersObject = {
        question_id: answers._id,
        answer: answers.answer,
      };
      answersArray.push(answersObject);
    });

    const formDataObject = {
      self_image_question: answersArray,
      self_image_completed_status: isAllAnswered,
      module_type: "self_image",
    };
    const result = await AddAllAnswersApi(JSON.stringify(formDataObject));
    if (result.code === 200) {
      setIsLoadingAll(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingAll(false);
    }
  };
  const checkIfAllAnswered = (data) => {
    data.map((value, index) => {
      if (value.answer === "") {
        setIsAllAnswered(false);
        return;
      } else {
        setIsAllAnswered(true);
      }
    });
  };
  useEffect(() => {
    let questionsArray = [];
    data.map((value, index) => {
      if (index === 0) {
        questionsArray.push({
          ...value,
          isExpanded: true,
          isLoading: false,
        });
      } else {
        questionsArray.push({
          ...value,
          isExpanded: false,
          isLoading: false,
        });
      }
      setQuestionMessages(questionsArray);
    });
    checkIfAllAnswered(data);
  }, [data]);
  return (
    <>
      <div className="row goal-statement-messages">
        <div className="col-12 my-4">
          <Divider />
        </div>
        <div className="col">
          {questionMessages.length > 0
            ? questionMessages.map((message, index) => {
                return (
                  <div className="card mb-2" key={message.id}>
                    <form
                      onSubmit={(e) => {
                        handleSubmit(e, index);
                      }}>
                      <Accordion expanded={message.isExpanded}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          onClick={() => expandArea(index)}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="svg-color">
                          <Typography>
                            {htmlDecode(message.question)}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {message.question_type == "textarea" ? (
                            <TextField
                              id="filled-multiline-flexible"
                              fullWidth
                              multiline
                              rows={5}
                              label={htmlDecode(message.question_placeholder)}
                              className={(classes.dense, "textarea-color")}
                              value={htmlDecode(message.answer)}
                              onChange={(e) => handleChange(index, e)}
                              variant="outlined"
                              name="message"
                            />
                          ) : (
                            <TextField
                              id="filled-multiline-flexible"
                              fullWidth
                              label={htmlDecode(message.question_placeholder)}
                              className={(classes.dense, "textarea-color")}
                              value={htmlDecode(message.answer)}
                              onChange={(e) => handleChange(index, e)}
                              variant="outlined"
                              name="message"
                            />
                          )}

                          <div className="d-flex justify-content-between mt-3">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleKimResponce(
                                  message.question,
                                  message.comment
                                );
                              }}
                              className="small-contained-button me-2">
                              {`${ownerName} Response`}
                            </button>
                            <button
                              className="small-contained-button"
                              disabled={message.isLoading}>
                              {message.isLoading ? "Saving..." : "Save & Next"}
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </form>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <div className="row">
        <div className="d-flex justify-content-between mt-3">
          <button onClick={handleKimReplies} className="small-contained-button">
            {`${ownerName} Replies`}
          </button>
          <button
            className="small-contained-button"
            disabled={isLoadingAll}
            onClick={handleSubmitAll}>
            {isLoadingAll ? "Saving..." : "Save All"}
          </button>
        </div>
      </div>

      <CustomDrawer
        isOpenDrawer={responceDrawer}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle={`${ownerName} Response`}
        componentToPassDown={
          <KimResponce question={question} data={comments} />
        }
      />
      <CustomDrawer
        isOpenDrawer={repliesDrawer}
        onOpenDrawer={handleOpenRepliesDrawer}
        onCloseDrawer={handleCloseRepliesDrawer}
        pageTitle={`${ownerName} Replies`}
        componentToPassDown={<KimResponce data={replies} />}
      />
    </>
  );
};

export default SelfImageMessage;
