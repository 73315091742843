import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "No", width: 100 },
  { field: "date", headerName: "Date", width: 100 },
  { field: "currency", headerName: "Currency", width: 100 },
  { field: "taxPercentage", headerName: "Tax%", width: 100 },
  { field: "taxAmount", headerName: "Tax Amount", width: 150 },
  { field: "subAmount", headerName: "Sub Amount", width: 150 },
  { field: "amount", headerName: "Total Amount", width: 150 },
  { field: "status", headerName: "Status", width: 100 },
];

export default function TransactionTable({ transactions }) {
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  console.log(transactions, "transactions");
  const getFormattedData = () => {
    const list = transactions.map((transaction, i) => {
      return {
        id: i + 1,
        date: transaction.transaction_date,
        currency: transaction.currency.toUpperCase(),
        amount: transaction.amount,
        subAmount: transaction.sub_amount,
        taxAmount: transaction.tax_amount,
        taxPercentage: transaction.tax_percentage + "%",
        status: capitalizeFirstLetter(transaction.transaction_status),
      };
    });
    return list;
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        className="data-table svg-color text-center "
        rows={getFormattedData()}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableColumnFilter={true}
        disableColumnMenu={true}
      />
    </div>
  );
}
