import { Avatar, Card, IconButton, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { attachment_icon, emoji_picker_icon, send_chat_icon } from "src/assets";
import CustomPopover from "../CustomPopover";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import CloseIcon from "@mui/icons-material/Close";
import CustomConfirmation from "../CustomConfirmation";
import { usePGIMode } from "src/Hooks/PGIModeContext";
import { useParams } from "react-router-dom";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  _dynamite_event_category_video_list_chat,
  delete_image_on_s3_for_chat,
  update_image_on_s3_for_chat,
} from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import moment from "moment";

export default function ChatCard({ eventDetail, setEventDetail }) {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [previews, setPreviews] = useState("");
  const [image, setImage] = React.useState("");
  const [chatMessage, setChatMessage] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const params = useParams();
  const { socket, userInfo } = useContentSetting();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [liveChat, setLiveChat] = useState([]);
  const [selectedComment, setSelectedComment] = useState({});
  const [oldImage, setOldImage] = React.useState("");

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_update_message(
      eventDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };
  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };
  const handleEmojiSelect = (emoji) => {
    setChatMessage((msg) => msg + emoji.native);
  };
  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };
  const handleEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };
  const handleAgreeDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    handle_delete_message(eventDetail, selectedComment, socket);
  };
  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setFormType("ADD");
  };
  const UploadImages = async (formData) => {
    // console.log(...formData, "formData");
    const result = await update_image_on_s3_for_chat(formData);
    return result.image_path;
  };
  const handleSendMessage = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setShowEmojiPicker(false);
    handle_send_message(image, chatMessage, userInfo, socket);
    handleRemoveAll();
    console.log("send message");
  };

  const handle_send_message = async (image, chatMessage, userInfo, socket) => {
    let file_url = "";
    if (image) {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("width", 600);
      formData.append("height", 700);
      file_url = await UploadImages(formData);
    }

    const event_id = eventDetail._id;
    // const video_id = eventDetail.feature_video._id;
    const message = chatMessage;
    const member = userInfo._id;
    // const member = {
    //   _id: userInfo._id,
    //   first_name: userInfo.first_name,
    //   last_name: userInfo.last_name,
    //   profile_image: userInfo.profile_image,
    // };

    let socket_object = {
      event_id,
      // video_id,
      message,
      file_url,
      member,
    };
    console.log(socket_object, "socket_objectsocket_object");

    socket.emit("live_event_message", socket_object);
  };
  const DeleteImages = async (image_path) => {
    const result = await delete_image_on_s3_for_chat(image_path);
  };
  const handle_delete_message = async (
    eventDetail,
    selectedComment,
    socket
  ) => {
    const event_id = eventDetail._id;
    if (selectedComment.file_url) {
      const formData = new FormData();
      formData.append("image", selectedComment.file_url);
      const result_delete = await DeleteImages(formData);
    }
    socket.emit("live_event_message_delete", {
      comment_id: selectedComment._id,
      event_id,
    });
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  const get_formatted_message = (chat, userInfo) => {
    return {
      ...chat,
      _id: chat._id,
      comment_date_time: chat.action_date_time,
      feeds: "",
      message: chat.message,
      user_info_action_for: {
        action_id: chat.member._id,
        action_by: "member_user",
        name: chat.member.first_name + " " + chat.member.last_name,
        profile_image: chat.member.profile_image,
      },
      replies: chat.replies ? chat.replies : [],
      updatedAt: chat.updatedAt,
      like_count: chat.like_count,
      createdAt: chat.createdAt,
      comment_is_self: chat.member._id === userInfo._id ? true : false,
    };
  };
  const get_comments_height = () => {
    if (document.getElementById("live-comments")) {
      const bottomHeight =
        document.getElementById("bottom-add-comment").offsetHeight;
      const height = document.getElementById("live-comments").offsetHeight;
      const commentsHeight = height - bottomHeight;
      return commentsHeight;
    }
  };
  const receive_new_live_message_by_socket = (
    data,
    eventDetail,
    userInfo,
    setLiveChat,
    setNewChat
  ) => {
    // if (data.video_id === eventDetail.feature_video._id) {

    // }
    const chat_formatted_obj = get_formatted_message(data.comment_id, userInfo);
    if (data.parent_message) {
      setLiveChat((prev) => {
        return prev.map((message) => {
          if (message._id === chat_formatted_obj.parent_message) {
            return {
              ...message,
              replies: message.replies
                ? [...message.replies, chat_formatted_obj]
                : [chat_formatted_obj],
            };
          } else {
            return message;
          }
        });
      });
    } else {
      setLiveChat((prev) => [...prev, chat_formatted_obj]);
    }
    // setTimeout(() => {
    //   scroll_to_bottom_on_message(chat_formatted_obj, userInfo, setNewChat);
    // }, 500);
  };
  const handle_update_message = async (
    eventDetail,
    oldImage,
    previews,
    image,
    chatMessage,
    socket,
    selectedComment
  ) => {
    let file_url = oldImage;
    if (image) {
      const formData = new FormData();
      formData.append("image", image);
      formData.append("width", 600);
      formData.append("height", 700);
      file_url = await UploadImages(formData);
    } else if (oldImage !== "" && previews == "") {
      const formData = new FormData();
      formData.append("image", oldImage);
      result_delete = await DeleteImages(formData);
      file_url = "";
    }

    const event_id = eventDetail._id;
    const message = chatMessage;

    socket.emit("live_event_message_update", {
      event_id,
      comment_id: selectedComment._id,
      message: message,
      file_url: file_url,
    });
  };
  const receive_update_live_message_by_socket = (
    data,
    userInfo,
    setLiveChat
    // setPinnedComments
  ) => {
    const chat_formatted_obj = get_formatted_message(data.comment_id, userInfo);
    setLiveChat((liveChat) =>
      liveChat.map((chat) => {
        if (chat._id === chat_formatted_obj._id) {
          const chat_replies = chat.replies;
          chat_formatted_obj.replies = chat_replies;
          return chat_formatted_obj;
        } else if (chat._id === chat_formatted_obj.parent_message) {
          return {
            ...chat,
            replies: chat.replies.map((message) => {
              if (message._id === chat_formatted_obj._id) {
                return chat_formatted_obj;
              } else {
                return message;
              }
            }),
          };
        } else {
          return chat;
        }
      })
    );
  };
  const receive_delete_live_message_by_socket = (data, setLiveChat) => {
    setLiveChat((liveChat) =>
      liveChat.filter((chat) => chat._id !== data.comment_id)
    );
    setLiveChat((comments) => {
      const comments_list = comments.map((comment) => {
        let set_comment = {};
        const array_filtered = comment.replies.filter(
          (child_comment) => child_comment._id !== data.comment_id
        );
        set_comment = {
          ...comment,
          replies: array_filtered,
        };
        return set_comment;
      });
      return comments_list;
    });
  };

  useEffect(() => {
    if (eventDetail) {
      // event handling
      socket.emit("live_event_room", eventDetail._id);
      socket.on("live_event_message_receiver", (data) => {
        receive_new_live_message_by_socket(
          data,
          eventDetail,
          userInfo,
          setLiveChat
        );
      });

      socket.on("live_event_message_update_receiver", (data) => {
        receive_update_live_message_by_socket(data, userInfo, setLiveChat);
      });

      socket.on("live_event_message_delete_receiver", (data) => {
        receive_delete_live_message_by_socket(data, setLiveChat);
      });
    }

    return () => {
      socket.off("live_event_message_receiver");
      socket.off("live_event_message_update_receiver");
      socket.off("live_event_message_delete_receiver");
    };
  }, [eventDetail]);
  const get_live_event_chat = async (setLiveChat, userInfo) => {
    const result = await _dynamite_event_category_video_list_chat(
      eventDetail._id
    );
    if (result.code === 200) {
      const chats = result.dynamite_event_list_chat.map((chat) => {
        return get_formatted_message(chat, userInfo);
      });
      setLiveChat(chats);
    } else {
    }
  };
  useEffect(() => {
    get_live_event_chat(setLiveChat, userInfo);
  }, []);
  return (
    <>
      <div className="col-12 col-lg-4 mt-4 mt-lg-0">
        <Card className="live-comments" id="live-comments">
          <div className="live-comments-list" style={{ height: 400 }}>
            {/* style={{ height: get_comments_height() }}> */}
            {liveChat.map((comment) => (
              <div>
                <div className="profile-comments inception p-2 mb-1 pb-1">
                  <div className="d-flex position-relative">
                    <div className="poster-name-image d-flex w-100">
                      <Avatar
                        src={
                          s3baseUrl +
                          comment?.user_info_action_for?.profile_image
                        }
                        alt="photoURL"
                        style={{ width: 30, height: 30 }}></Avatar>
                      <div className="w-100">
                        <div className="text-start set-title-width poster-name ps-1 w-100">
                          <span className="card-title mb-0 fs-12 fw-500">
                            {htmlDecode(comment?.user_info_action_for?.name)}
                          </span>

                          <span className="date-color float-end pe-4 fw-400 fs-8 mt-1">
                            {moment(comment?.createdAt).format("YYYY-MM-DD")}
                          </span>
                        </div>

                        <div className="post-description ps-1 pt-0 fs-12 fw-400 live-event-comment-message">
                          <span className="text-start date-color mb-0">
                            {htmlDecode(comment?.message)}
                          </span>
                        </div>
                        <div className="col-12">
                          {comment.file_url && (
                            <a
                              href={s3baseUrl + comment.file_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="comment-view-attachment">
                              <span className="chat-image-preview mt-2">
                                <img src={s3baseUrl + comment.file_url} />
                              </span>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    {comment.comment_is_self && (
                      <div className="affirmation-dots">
                        <CustomPopover menu={MENU_OPTIONS} data={comment} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="bottom-add-comment" id="bottom-add-comment">
            <form>
              <div className="add-event-comment">
                <div className="add-event-comment-text-field">
                  <TextField
                    className="mt-2 inception-input"
                    id="outlined-multiline-static"
                    label="Write a comment…"
                    multiline
                    rows={2}
                    name="description"
                    variant="outlined"
                    style={{ width: "100%" }}
                    required={true}
                    value={chatMessage}
                    onChange={(e) => setChatMessage(e.target.value)}
                  />
                  {formType === "EDIT" && (
                    <IconButton
                      className="comment-cancel-update"
                      onClick={handleRemoveAll}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="text-start mt-3 mb-2 event-chat-emoji-picker-button">
                    <img
                      src={emoji_picker_icon}
                      onClick={() => {
                        setShowEmojiPicker((prevState) => !prevState);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    {previews && (
                      <span className="chat-image-preview mt-2 me-auto">
                        <span onClick={handleRemove} className="cross-icon">
                          x
                        </span>
                        <img src={previews} />
                      </span>
                    )}
                    <div className="mt-2 d-flex">
                      <span className="event-chat-upload-button mt-2">
                        <input
                          color="primary"
                          accept="image/*"
                          type="file"
                          id="event-chat-upload-button"
                          style={{ display: "none" }}
                          name="affirmationImage"
                          onChange={handleUpload}
                        />
                        <label htmlFor="event-chat-upload-button">
                          <img src={attachment_icon} alt="" srcSet="" />
                        </label>
                      </span>
                    </div>

                    {chatMessage ? (
                      <div
                        className="text-end mt-3 ms-1 mb-2 event-chat-send-button"
                        onClick={
                          !isSubmitting && formType === "ADD"
                            ? handleSendMessage
                            : handleMessageUpdate
                        }>
                        <img src={send_chat_icon} alt="" srcSet="" />
                      </div>
                    ) : (
                      <Tooltip title="Write a comment">
                        <div className="text-end mt-3 ms-1 mb-2 event-chat-send-button">
                          <img src={send_chat_icon} alt="" srcSet="" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Card>
      </div>
      {showEmojiPicker && (
        <div className="col-12 col-lg-4 mt-4 mt-lg-0 ms-auto">
          <Picker data={data} onEmojiSelect={handleEmojiSelect} />
        </div>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this Comment?"}
        handleAgree={handleDelete}
      />
    </>
  );
}
