import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import faker from "faker";
import { useSnackbar } from "notistack";
// material
import {
  Box,
  Card,
  Container,
  Link,
  Typography,
  Stack,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlayLessonIcon from "@mui/icons-material/PlayLesson";
import { lessonList, programmDetail } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import { htmlDecode } from "src/utils/convertHtml";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function LessonListing() {
  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [lessonData, setLessonData] = useState([]);
  const [programmesDetail, setProgrammesDetail] = useState({});

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.program_slug);
    if (result.code === 200) {
      setProgrammesDetail(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programs`);
    }
  };

  const getLessonList = async () => {
    const result = await lessonList(params.program_slug);
    if (result.code === 200) {
      setLessonData(result.lesson);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programs`);
    }
  };

  useEffect(() => {
    getLessonList();
    getProgrammesDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(`/programs`)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h1>{programmesDetail.title}</h1>
          </div>
        </div>
        <div className="row">
          {lessonData.length > 0 ? (
            lessonData.map((lesson, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                <Card
                  className="programm-card h-100"
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate(`/lessons/${lesson.lesson_slug}`, {
                      state: lesson,
                    })
                  }>
                  <Box
                    sx={{
                      position: "relative",
                      cursor: "pointer",
                    }}>
                    <ProgrammesImgStyle
                      alt="lessons"
                      src={s3baseUrl + lesson.lesson_images.thumbnail_2}
                    />
                  </Box>

                  <Stack
                    spacing={2}
                    // sx={{ p: 3 }}
                    className="lesson-content">
                    <h3>{htmlDecode(lesson.title)}</h3>
                    {/* </Link> */}
                    <p className="programme-card-desc">
                      {htmlDecode(lesson.short_description)}
                    </p>
                  </Stack>
                </Card>
              </div>
            ))
          ) : (
            <RecordNotFound title="Lessons" />
          )}
        </div>
      </div>
    </>
  );
}
