import React, { useCallback, useEffect, useRef, useState } from "react";
import { get_feeds_detail, get_feeds_list } from "src/DAL/Community/Community";
import CreatePost from "./CreatePost";
import OpenCreatePostCard from "./OpenCreatePostCard";
import PostCard from "./PostCard";
import { CircularProgress, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FeedDetailPopup from "./FeedDetailPopup";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useNavigate } from "react-router-dom";
import { RecordNotFound } from "src/components/_dashboard/programmes";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

function MainPart({ showInfoBox, setShowInfoBox, userInfo }) {
  const { socket } = useContentSetting();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [feedsList, setFeedsList] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [likedData, setLikedData] = useState({});
  const [showAllCommentComponent, setShowAllCommentComponent] = useState(false);
  const [isLoadingSection, setIsLoadingSection] = useState(true);
  const [feedType, setFeedType] = useState("general");
  const [formAction, setFormAction] = useState("ADD");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [selectedFeed, setSelectedFeed] = useState({});
  const [loadMore, setLoadMore] = useState(
    `api/feeds/list_with_pagination?page=0&limit=50`
  );
  // popup states
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totlePages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totlePages, pageNumber, loadMorePath, observer]
  );

  const handleCloseDetailBox = () => {
    setOpenDetailModal(false);
  };

  const handleShowDetails = (post) => {
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleSelectedImage = (post, index) => {
    setSelectedIndex(index);
    setSelectedPost(post);
    setOpenDetailModal(true);
  };

  const handleOpenImageBox = () => {
    setOpenCreateModal(true);
    setFeedType("image");
  };
  const handleOpenVideoBox = () => {
    setOpenCreateModal(true);
    setFeedType("video");
  };

  const handleSuccessDelete = (post) => {
    setOpenDetailModal(false);
    const updated_feed = feedsList.filter((feed) => feed._id !== post._id);
    setFeedsList(updated_feed);
  };

  const handleSuccessUpdate = async (post) => {
    handleUpdateSpecificFeed(post._id);
    setOpenDetailModal(false);
  };

  const handleUpdateSpecificFeed = async (id) => {
    const result = await get_feeds_detail(id);

    if (result.code === 200) {
      const data = feedsList.map((feed) => {
        if (feed._id === id) {
          return { ...result.feeds, comment: result.feeds.comment };
        } else {
          return feed;
        }
      });
      setFeedsList(data);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      // setIsLoading(false);
    }
  };

  const handleUpdateShowAll = async (id, val) => {
    const data = feedsList.map((feed) => {
      if (feed._id === id) {
        return { ...feed, is_show_all: val };
      } else {
        return feed;
      }
    });
    setFeedsList(data);
  };
  // -----------------------------------
  const getFeedsListing = async () => {
    const result = await get_feeds_list(loadMore);
    if (result.code === 200) {
      const new_feeds = result.feeds.map((feed) => {
        return { ...feed, is_show_all: false };
      });
      var newArray = feedsList.concat(new_feeds);
      setFeedsList(newArray);
      setPageNumber(pageNumber + 1);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingSection(false);
      setIsLoadingMore(false);
    }
  };

  const getFeedsListingOnAddFeed = async (path) => {
    setIsLoadingSection(true);
    const result = await get_feeds_list(path);
    if (result.code === 200) {
      setFeedsList(result.feeds);
      setPageNumber(0);
      setTotlePages(result.total_pages);
      setLoadMorePath(result.load_more);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
      setIsLoadingSection(false);
    }
  };

  const getFeedsListingOnDelete = async (id) => {
    const data = feedsList.filter((feed) => feed._id !== id);
    setFeedsList(data);
  };

  const loadMoreData = () => {
    setLoadMore(loadMorePath);
    setIsLoadingMore(true);
  };

  const handleOpenSimpleBox = () => {
    setOpenCreateModal(true);
    setFeedType("general");
  };

  const handleFeedEdit = (selected_feed) => {
    setSelectedFeed(selected_feed);
    setOpenCreateModal(true);
    setFormAction("EDIT");
  };

  const update_feed_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          set_feed = {
            ...feed,
            like_count: data.action_response.like_count,
            top_liked_user: data.action_response.top_liked_user,
            coins_count: data.action_response.coins_count,
          };
          if (userInfo._id == data.action_by) {
            set_feed.is_liked = data.action_response.is_liked;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_feed_gratitude_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          set_feed = {
            ...feed,
            gratitude_count: data.action_response.gratitude_count,
            top_gratitude_user: data.action_response.top_gratitude_user,
            coins_count: data.action_response.coins_count,
          };
          if (userInfo._id == data.action_by) {
            set_feed.is_gratitude = data.action_response.is_gratitude;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_comment_likes_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          if (data.child_comment) {
            set_feed = {
              ...feed,
              comment: {
                ...feed.comment,
                child_comment: feed.comment.child_comment.map(
                  (single_child_comment) => {
                    let child_comment_object = {};
                    if (single_child_comment._id == data.comment) {
                      child_comment_object = {
                        ...single_child_comment,
                        like_count: data.action_response.comment_like_count,
                      };
                      if (userInfo._id == data.action_by) {
                        child_comment_object.is_liked =
                          data.action_response.is_liked;
                      }
                      return child_comment_object;
                    } else {
                      return single_child_comment;
                    }
                  }
                ),
              },
            };
          } else {
            if (userInfo._id == data.action_by) {
              set_feed = {
                ...feed,
                comment: {
                  ...feed.comment,
                  is_liked: data.action_response.is_liked,
                  like_count: data.action_response.comment_like_count,
                },
              };
            } else {
              set_feed = {
                ...feed,
                comment: {
                  ...feed.comment,
                  like_count: data.action_response.comment_like_count,
                },
              };
            }
          }
          return set_feed;
        } else {
          return feed;
        }
      });

      return feed_list;
    });
  };

  const add_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          if (userInfo._id == data.action_by) {
            set_feed = {
              ...feed,
              comment: {
                ...data.action_response.comment,
                comment_is_self: true,
              },
              comment_count: data.action_response.feed.comment_count,
            };
          } else {
            set_feed = {
              ...feed,
              comment: data.action_response.comment,
              comment_count: data.action_response.feed.comment_count,
            };
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const add_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          if (feed.comment._id === data.parent_comment) {
            let child_comments_array = data.child_comments_array.map(
              (child) => {
                let set_comment_object = {};
                if (child.user_info_action_for.action_id === userInfo._id) {
                  set_comment_object = {
                    ...child,
                    comment_is_self: true,
                  };
                } else {
                  set_comment_object = {
                    ...child,
                    comment_is_self: false,
                  };
                }
                return set_comment_object;
              }
            );

            set_feed = {
              ...feed,
              comment: {
                ...feed.comment,
                child_comment: child_comments_array,
              },
              comment_count: data.action_response.feed.comment_count,
            };
          } else {
            set_feed = feed;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const edit_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          if (feed.comment._id === data.parent_comment) {
            const set_child_comments = feed.comment.child_comment.map(
              (child_comment_object) => {
                let set_child_comment = {};
                if (
                  child_comment_object._id === data.action_response.comment._id
                ) {
                  let set_comment_object = {};
                  if (userInfo._id == data.action_by) {
                    set_comment_object = {
                      ...child_comment_object,
                      message: data.action_response.comment.message,
                      comment_is_self: true,
                    };
                  } else {
                    set_comment_object = {
                      ...child_comment_object,
                      message: data.action_response.comment.message,
                    };
                  }
                  return (set_child_comment = set_comment_object);
                } else {
                  return (set_child_comment = child_comment_object);
                }
              }
            );
            set_feed = {
              ...feed,
              comment: {
                ...feed.comment,
                child_comment: set_child_comments,
              },
              comment_count: data.action_response.feed.comment_count,
            };
          } else {
            set_feed = feed;
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const update_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          if (userInfo._id == data.action_by) {
            set_feed = {
              ...feed,
              comment: {
                ...feed.comment,
                message: data.action_response.comment.message,
                comment_is_self: true,
              },
              comment_count: data.action_response.feed.comment_count,
            };
          } else {
            set_feed = {
              ...feed,
              comment: {
                ...feed.comment,
                message: data.action_response.comment.message,
              },
              comment_count: data.action_response.feed.comment_count,
            };
          }
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const delete_comment_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          if (feed.comment?._id === data.comment) {
            let set_feed = {};
            if (
              data.action_response.comment?.user_info_action_for.action_id ===
              userInfo._id
            ) {
              set_feed = {
                ...feed,
                comment: {
                  ...data.action_response.comment,
                  comment_is_self: true,
                },
                comment_count: data.action_response.feed.comment_count,
              };
            } else {
              set_feed = {
                ...feed,
                comment: data.action_response.comment,
                comment_count: data.action_response.feed.comment_count,
              };
            }
            return set_feed;
          } else {
            return feed;
          }
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const end_stream_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id) {
          let set_feed = {};
          set_feed = {
            ...feed,
            is_live_streaming: false,
          };
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  const delete_comment_reply_by_socket = (data) => {
    setFeedsList((feeds) => {
      const feed_list = feeds.map((feed) => {
        if (feed._id === data.feed_id && !feed.is_show_all) {
          let set_feed = {};
          const array_filtered = feed.comment.child_comment.filter(
            (comment) => comment._id !== data.comment
          );
          set_feed = {
            ...feed,
            comment: { ...feed.comment, child_comment: array_filtered },
            comment_count: data.action_response.feed.comment_count,
          };
          return set_feed;
        } else {
          return feed;
        }
      });
      return feed_list;
    });
  };

  // useEffect(() => {
  //   socket.emit("live_event_room", "live_feed_room");

  //   socket.on("end_stream_event_listener", (data) => {
  //     end_stream_by_socket(data);
  //   });

  //   socket.on("live_feed_room_reciever", (data) => {
  //     if (data.action === "feedlike" || data.action === "feedunlike") {
  //       update_feed_likes_by_socket(data);
  //     } else if (data.action === "gratitude") {
  //       update_feed_gratitude_by_socket(data);
  //     } else if (
  //       data.action === "commentlike" ||
  //       data.action === "commentunlike"
  //     ) {
  //       update_comment_likes_by_socket(data);
  //     } else if (data.action === "add_comment") {
  //       add_comment_by_socket(data);
  //     } else if (data.action === "edit_comment") {
  //       update_comment_by_socket(data);
  //     } else if (data.action === "delete_comment") {
  //       delete_comment_by_socket(data);
  //     } else if (data.action === "add_comment_reply") {
  //       add_comment_reply_by_socket(data);
  //     } else if (data.action === "edit_comment_reply") {
  //       edit_comment_reply_by_socket(data);
  //     } else if (data.action === "delete_comment_reply") {
  //       delete_comment_reply_by_socket(data);
  //     }
  //   });

  //   return () => {
  //     // socket.off("live_feed_room_reciever");
  //   };
  // }, [openDetailModal]);

  useEffect(() => {
    getFeedsListing();
  }, [loadMore]);
  return (
    <>
      <OpenCreatePostCard
        handleOpenSimpleBox={handleOpenSimpleBox}
        userInfo={userInfo}
        handleOpenImageBox={handleOpenImageBox}
        handleOpenVideoBox={handleOpenVideoBox}
      />
      {isLoadingSection ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : feedsList && feedsList.length > 0 ? (
        feedsList.map((feeds_list, index) => {
          return (
            <PostCard
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              feeds_list={feeds_list}
              index={index}
              userInfo={userInfo}
              getFeedsListingOnDelete={getFeedsListingOnDelete}
              handleShowDetails={handleShowDetails}
              handleFeedEdit={handleFeedEdit}
              getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
              handleUpdateSpecificFeed={handleUpdateSpecificFeed}
              handleUpdateShowAll={handleUpdateShowAll}
              key={index}
              handleSelectedImage={handleSelectedImage}
              openDetailModal={openDetailModal}
            />
          );
        })
      ) : (
        <RecordNotFound title="Feeds" />
      )}
      {!isLoadingSection && totlePages > pageNumber ? (
        <div className="col-12 text-center favourite-buttton-box">
          <button
            ref={lastBookElementRef}
            className="small-contained-button mt-3"
            onClick={loadMoreData}
            id="load-more-feed">
            {isLoadingMore ? "Loading..." : "Load More"}
          </button>
        </div>
      ) : (
        ""
      )}

      <CreatePost
        setFeedType={setFeedType}
        feedType={feedType}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        getFeedsListing={getFeedsListingOnAddFeed}
        userInfo={userInfo}
        selectedFeed={selectedFeed}
        formAction={formAction}
        setFormAction={setFormAction}
        handleSuccessUpdate={handleSuccessUpdate}
      />

      <Modal
        open={openDetailModal}
        onClose={handleCloseDetailBox}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="feed-detail-popup">
        <>
          <div className="row post-detail-box">
            <span
              className="cross-show-all-likes-model"
              onClick={handleCloseDetailBox}>
              x
            </span>
          </div>
          <FeedDetailPopup
            handleCloseDetailBox={handleCloseDetailBox}
            post_id={selectedPost._id}
            feed={selectedPost}
            getFeedsListingOnAddFeed={handleUpdateSpecificFeed}
            handleSuccessDelete={handleSuccessDelete}
            handleSuccessUpdate={handleSuccessUpdate}
            feeds_type="general"
            // event_id={event_id}
            openDetailModal={openDetailModal}
            // feedSetting={feedSetting}
            selectedImageIndex={selectedIndex}
            feedsList={feedsList}
          />
        </>
      </Modal>
    </>
  );
}

export default MainPart;
