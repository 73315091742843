import React from "react";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { htmlDecode } from "src/utils/convertHtml";

const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function CustomMUICard({ data, handleClick, className }) {
  // console.log(data, "dateeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
  return (
    <div className={`row ${className}`}>
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card className="mui-custom-card h-100" sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}>
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                />
              </Box>

              <Stack spacing={2} className="mui-custom-card-content">
                <h3>{htmlDecode(item.card_title)}</h3>
                {item.card_short_description && (
                  <p className="mui-custom-card-description">
                    {htmlDecode(item.card_short_description)}
                  </p>
                )}
              </Stack>
              <div className="row">
                <div className="col-12">
                  <p className="date-portals d-flex">
                    <span className="date-portals-title">Event Date:</span>
                    <span>
                      {" "}
                      {item?.client_start_date} to {item?.client_end_date}
                    </span>
                  </p>
                </div>
              </div>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
