import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import { makeStyles } from "@mui/styles";
import { videos_listing } from "src/DAL/HelpingVideos/AdminHelpingVideos";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function HelpingVideos() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [query, setQuery] = useState("");
  const [adminVideos, setAdminVideos] = useState([]);
  const programsList = async () => {
    const result = await videos_listing();
    if (result.code == 200) {
      setAdminVideos(result.helpingvideo);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleChangeSearch = (e) => {
    setQuery(e.target.value);
  };
  const getFilteredData = (query) => {
    let dataToFilter = [...adminVideos];
    if (query) {
      let resultFilter = dataToFilter.filter((post) =>
        post.title.toLowerCase().includes(query.toLowerCase())
      );
      return resultFilter;
    } else {
      return dataToFilter;
    }
  };
  useEffect(() => {
    programsList();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Helping videos </h2>
        </div>
        <div className="col-lg-6 col-sm-12 text-end">
          <FormControl variant="outlined" sx={{ marginTop: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">
              Search
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              className="search-bar"
              type="text"
              variant="small"
              onChange={handleChangeSearch}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Search"
            />
          </FormControl>
        </div>
        {adminVideos.length > 0 ? (
          <>
            {getFilteredData(query)?.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  <div className="card mt-4 pods-cards-shadow cursor h-100">
                    <img
                      src={s3baseUrl + value.thumbnail_image.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Program"
                      onClick={() =>
                        navigate(
                          `/helping-videos/${value.helping_video_slug}`,
                          {
                            state: value,
                          }
                        )
                      }
                    />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <h3 className="h2-heading">{value.title}</h3>
                        </div>

                        <div className="col-2 menu-option"></div>
                      </div>
                      <p className="programme-card-desc">
                        {value.short_description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <RecordNotFound title="Helping Videos" />
        )}
      </div>
    </div>
  );
}
