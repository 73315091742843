import React, { useEffect, useState } from "react";
import MainPart from "./MainPart";

import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
export default function Feed() {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const { userInfo } = useContentSetting();

  return (
    <div className="container new-community-page">
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2>Community News Feed</h2>
        </div>
      </div>
      <div className="mobile-case d-block d-lg-none">
        {window.screen.width < 911 && <MainPart />}
      </div>
      <div className="row d-none d-lg-flex mt-3">
        <div className="col-1"></div>
        <div className="col-10 community-main-section pb-4">
          <MainPart
            showInfoBox={showInfoBox}
            setShowInfoBox={setShowInfoBox}
            userInfo={userInfo}
            event_id=""
          />
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
}
