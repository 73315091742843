import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";

import {
  live_events_list_api,
  //   _list_of_member_for_chat_against_event,
} from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomMUICard from "src/components/CustomMUICard";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function Portals() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});
  const handleClick = (object) => {
    navigate(`/portals/${object.event_slug}`, { state: object });
  };

  const getLiveEventsList = async () => {
    const result = await live_events_list_api();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.member_dynamite_event.map((dynamite_event) => {
        const originalDate = new Date(dynamite_event.start_date);
        const newDate = new Date(originalDate);
        newDate.setDate(originalDate.getDate() + dynamite_event.no_of_days);
        const newDateString = newDate.toISOString().split("T")[0];

        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          end_date: newDateString,
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getLiveEventsList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Danielle's Events / Portals</h2>
          </div>
        </div>
        {eventsList.length > 0 ? (
          <CustomMUICard data={eventsList} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Danielle's Events / Portals" />
        )}
      </div>
    </>
  );
}
