import { invokeApi } from "../../bl_libs/invokeApi";

export const SelfImageListApi = async (data) => {
  const requestObj = {
    path: `api/member/get_self_image?module_type=journal_prompt`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const UploadGalleryImageOnS3 = async (data) => {
  const requestObj = {
    path: `api/self_image_question/upload_gallery_images/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddGalleryImage = async (data) => {
  const requestObj = {
    path: `api/member/update_self_image_gallery/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteGalleryImage = async (data) => {
  const requestObj = {
    path: `api/self_image_question/delete_self_image_image_from_s3/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddSingleAnswerApi = async (data) => {
  const requestObj = {
    path: `api/member/update_self_image_question/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddAllAnswersApi = async (data) => {
  const requestObj = {
    path: `api/member/update_all_self_image_question/`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
