import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CountriesList,
  confirm_one_time_payment,
  confirm_subscription_payment,
  get_intent_client_secret_for_one_time,
  get_site_setting,
  pay_now_for_subscription,
  requestDetailApi,
} from "src/DAL/Payment/Payment";
import TransactionList from "../Billing/TransactionList";
import CardInformation from "./CardInformation";
import RequestCard from "./RequestCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStripe } from "@stripe/react-stripe-js";
import Autocomplete from "@mui/material/Autocomplete";
import { convertCurrencyToSign } from "src/utils/constants";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const PendingRequests = (props) => {
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const stripe = useStripe();
  const [requestData, setRequestData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [clientSecret, setClientSecret] = useState("");
  const [countryState, setCountryState] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [allCountryState, setAllCountryState] = useState([]);
  const [resPostData, setResPostData] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [inputs, setInputs] = React.useState({
    state: "",
  });
  //Getting lesson detail
  const getRequestDetail = async () => {
    const result = await requestDetailApi(params.request_slug);
    if (result.code === 200) {
      if (result.payment_request.is_first_paid === true) {
        navigate(`/active-request/${params.request_slug}`);
      }
      if (result.payment_request_info.request_type === "recurring") {
        setTotalPrice(result.payment_request_info.initial_amount);
      } else {
        setTotalPrice(result.payment_request_info.total_amount);
      }

      setRequestData(result.payment_request_info);
      get_category_list();
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };
  const handleChildCategory = async (value) => {
    if (value) {
      setCountryState("");
      setAllCountryState(value?.states);
      setTaxAmount(value.tax);
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    // console.log(value, "===========================");
    setCountryState(value);
    if (value.tax) {
      setTaxAmount(value?.tax);
    }
  };
  const payNowForSubscription = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
      source_token: token,
      country: selectedParent?.country_name,
      state: countryState.state_name ? countryState?.state_name : countryState,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await pay_now_for_subscription(postData);

    if (result.code === 200) {
      setIsLoadingCard(false);
      enqueueSnackbar("Payment succeeded successfully.", {
        variant: "success",
      });
      navigate(`/payment-request`);
    } else if (result.code === 210) {
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
        price_id: result.stripe_initial_price_id,
        recurring_price_id: result.strip_recurring_price_id,
        subscription_id: result.strip_subscription_id,
      };
      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);
      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const getIntentClientSecretForOneTime = async (token, cardElement) => {
    const postData = {
      payment_request_slug: requestData.payment_request_slug,
      country: selectedParent?.country_name,
      state: countryState.state_name ? countryState?.state_name : countryState,
    };

    // if client secret is already generated
    if (clientSecret && resPostData) {
      handleSecureCard(clientSecret, cardElement, resPostData);
      return;
    }

    const result = await get_intent_client_secret_for_one_time(postData);

    if (result.code === 200) {
      // confirm payment data
      const postData = {
        payment_request_slug: requestData.payment_request_slug,
      };

      // save data for failure use
      setClientSecret(result.client_secret);
      setResPostData(postData);

      // handle secure card action
      handleSecureCard(result.client_secret, cardElement, postData);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingCard(false);
    }
  };

  const confirmCardPayment = async (postData) => {
    if (requestData.request_type === "recurring") {
      const result = await confirm_subscription_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    } else {
      const result = await confirm_one_time_payment(postData);

      if (result.code === 200) {
        setIsLoadingCard(false);
        enqueueSnackbar("Payment succeeded successfully.", {
          variant: "success",
        });
        navigate(`/payment-request`);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoadingCard(false);
      }
    }
  };

  const handleSecureCard = (client_secret, cardElement, postData) => {
    enqueueSnackbar("Processing card...", {
      variant: "info",
    });

    stripe
      .confirmCardPayment(client_secret, {
        payment_method: {
          card: cardElement,
        },
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        setIsLoadingCard(false);
        if (result.error) {
          setIsLoadingCard(false);
          enqueueSnackbar(result.error.message, { variant: "error" });
          return;
        }

        confirmCardPayment(postData);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleCardAction = (card_token, cardElement) => {
    if (requestData.request_type === "recurring") {
      payNowForSubscription(card_token, cardElement);
    } else {
      getIntentClientSecretForOneTime(card_token, cardElement);
    }
  };
  const get_category_list = async () => {
    const response = await CountriesList();
    if (response.code === 200) {
      setCategoriesData(response.country);
      setIsLoading(false);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
      navigate(`/payment-request`);
    }
  };
  useEffect(() => {
    getRequestDetail();
  }, [params.request_slug]);
  useEffect(() => {
    let price = 0;
    let percentage = 0;
    let tax_price = 0.0;
    let total_price = 0;
    if (requestData.request_type === "recurring") {
      price = requestData.initial_amount;
    } else {
      price = requestData.total_amount;
    }

    percentage = price / 100;
    if (!isNaN(percentage) && !isNaN(taxAmount)) {
      tax_price = percentage * taxAmount;
      total_price = price + tax_price;
      setTotalTax(tax_price.toFixed(2));
      setTotalPrice(total_price.toFixed(2));
    }
  }, [countryState]);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <IconButton
        className="back-screen-button"
        onClick={() => navigate("/payment-request")}>
        <ArrowBackIcon />
      </IconButton>
      <h2 className="mb-3">Payment Request Information</h2>
      <RequestCard bill={requestData} btn_title="View detail" />
      <div className="row mt-4">
        <div className="col-lg-6 col-md-12">
          <Autocomplete
            // multiple
            id="tags-outlined"
            options={categoriesData}
            getOptionLabel={(option) => option?.country_name}
            filterSelectedOptions
            value={selectedParent}
            onChange={(event, newValue) => {
              setSelectedParent(newValue);
              handleChildCategory(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Country*" placeholder="Country" />
            )}
          />
        </div>
        {allCountryState.length > 0 ? (
          <div className="col-lg-6 col-md-12">
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-name-label">State *</InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={countryState}
                onChange={handleChange}
                input={<OutlinedInput label="State *" />}
                style={{ color: "white" }}>
                {allCountryState.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name.state_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div className="col-lg-6 col-md-12">
            <TextField
              id="outlined-basic"
              label="State"
              variant="outlined"
              fullWidth
              required
              name="countryState"
              value={countryState}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
      <div class="row mt-4">
        <div className="col-7"></div>
        <div className="col-5 text-end px-4 mb-2">
          <div className="justify-content-between d-flex">
            <div>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Plan Price:{" "}
              </Typography>
            </div>
            <div>
              {requestData.request_type === "recurring" ? (
                <Typography sx={{ fontSize: "14px" }}>
                  {convertCurrencyToSign(requestData.currency)}
                  {requestData.initial_amount}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "14px" }}>
                  {" "}
                  {convertCurrencyToSign(requestData.currency)}
                  {requestData.total_amount}
                </Typography>
              )}
            </div>
          </div>
          <div className="mt-2 justify-content-between d-flex">
            <div>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Tax:{" "}
              </Typography>
            </div>
            <div>
              <Typography sx={{ fontSize: "14px" }}>
                {" "}
                {convertCurrencyToSign(requestData.currency)}
                {totalTax}
              </Typography>
            </div>
          </div>

          <div className="mt-2 justify-content-between d-flex">
            <div>
              <Typography sx={{ mr: 12, fontWeight: "bold", fontSize: "14px" }}>
                Total Price:{" "}
              </Typography>
            </div>
            <div>
              <Typography sx={{ fontSize: "14px" }}>
                {" "}
                {convertCurrencyToSign(requestData.currency)}
                {totalPrice}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <CardInformation
        isLoading={isLoadingCard}
        setIsLoading={setIsLoadingCard}
        handleCardAction={handleCardAction}
      />
    </div>
  );
};

export default PendingRequests;
