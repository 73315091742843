import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Avatar, InputBase } from "@mui/material";
import {
  add_new_feed,
  update_feed,
  update_feed_image_on_s3,
} from "src/DAL/Community/Community";
import { useNavigate } from "react-router-dom";
import GeneralMenuPopup from "src/components/EmojiPickerPopup";
import { useRef } from "react";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};

export default function CreatePost({
  openCreateModal,
  setOpenCreateModal,
  setFeedType,
  feedType,
  getFeedsListing,
  userInfo,
  formAction,
  selectedFeed,
  setFormAction,
  handleSuccessUpdate,
}) {
  const { socket } = useContentSetting();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [previews, setPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [feedId, setFeedId] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef(null);
  const [inputs, setInputs] = useState({
    feedAppearBy: "public",
    VideoUrl: "",
    description: "",
    LiveUrl: "",
  });
  const handleRemove = () => {
    setThumbnail("");
  };
  const handleRemoveImage = (path) => {
    setPreviews((images) => {
      return images.filter((image) => image !== path);
    });
  };

  const UploadImages = async (formData) => {
    const result = await update_feed_image_on_s3(formData);
    return result.images_obj;
  };

  const handleUpload = async (event) => {
    let setImageObject = {};
    const fileList = event.target.files;
    const images_array = [];
    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };
      images_array.push(setImageObject);
    }
    setPreviews((prevFiles) => [...prevFiles, ...images_array]);
  };

  const handleUploadThumbnail = async (event) => {
    const targetFile = event.target.files[0];
    setThumbnail(targetFile);
  };

  const handleClose = () => {
    setOpenCreateModal(false);
    setInputs({
      feedAppearBy: "public",
      VideoUrl: "",
      description: "",
    });
    setPreviews([]);
    setImage("");
    setFormAction("ADD");
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    if (
      !inputs.VideoUrl &&
      !inputs.description &&
      !inputs.LiveUrl &&
      !image &&
      !thumbnail &&
      previews.length < 1
    ) {
      enqueueSnackbar("Please add data to be posted", {
        variant: "error",
      });
      return;
    }

    setIsLoading(true);

    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image.path;
      }
    });

    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();
      formData.append("feed_type", feedType);
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);

      if (feedType === "image") {
        formData.append("feed_images", JSON.stringify(img_results));
      }

      const result = await add_new_feed(formData);
      if (result.code === 200) {
        setInputs({
          feedAppearBy: "public",
          VideoUrl: "",
          description: "",
        });
        getFeedsListing(`api/feeds/list_with_pagination?page=0&limit=50`);
        const socketData = {
          action: "feedadd",
          feed_id: result.feed._id,
          token: localStorage.getItem("token"),
          creator_id: result.action_response.creator_id,
          action_by: result.action_response.sender,
          action_response: result.action_response,
        };
        socket.emit("feed_room_action_event", socketData);
        setIsLoading(false);
        handleClose();
        enqueueSnackbar(result.message, {
          variant: "success",
        });
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleCancelUpdate = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();

    if (
      inputs.description === "" &&
      inputs.VideoUrl === "" &&
      inputs.LiveUrl === "" &&
      image === ""
    ) {
      enqueueSnackbar(
        "There should be one of the content in feed [description, video, image, livestream]",
        { variant: "error" }
      );
      return;
    }
    setIsLoading(true);
    const results = previews.map((image, index) => {
      if (image.type === "file") {
        const formData = new FormData();
        formData.append("image", image.path);
        formData.append("width", 300);
        const result = UploadImages(formData);
        return result;
      } else {
        return image;
      }
    });

    Promise.all(results).then(async (img_results) => {
      const formData = new FormData();
      formData.append("feed_type", feedType);
      formData.append("video_url", inputs.VideoUrl);
      formData.append("description", inputs.description);

      if (feedType === "image") {
        formData.append("feed_images", JSON.stringify(img_results));
      }

      // if (image === "" && selectedFeed.feed_type === "image") {
      //   formData.append("is_remove_image", true);
      // }
      const result = await update_feed(formData, feedId);
      if (result.code === 200) {
        setInputs({
          feedAppearBy: "public",
          VideoUrl: "",
          description: "",
        });

        if (!window.location.pathname.includes("/feed-detail")) {
          getFeedsListing(`api/feeds/list_with_pagination?page=0&limit=50`);
        } else {
          getFeedsListing(feedId);
        }

        setIsLoading(false);
        handleClose();
        enqueueSnackbar(result.message, {
          variant: "success",
        });
        handleSuccessUpdate(selectedFeed);
      } else {
        setIsLoading(false);
        enqueueSnackbar(result.message, { variant: "error" });
      }
    });
  };

  useEffect(() => {
    if (formAction === "EDIT") {
      console.log(selectedFeed, "selectedFeedselectedFeed");
      setInputs({
        VideoUrl: selectedFeed.video_url,
        description: selectedFeed.description,
      });
      setFeedType(selectedFeed.feed_type);
      setFeedId(selectedFeed._id);
      if (
        selectedFeed.feed_type === "image" &&
        selectedFeed.feed_images &&
        selectedFeed.feed_images.length > 0
      ) {
        setPreviews(selectedFeed.feed_images);
        setImage(s3baseUrl + selectedFeed.image?.thumbnail_1);
      }
    }
  }, [formAction]);

  return (
    <div>
      <Modal
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <form
          className="create-post-title-form"
          onSubmit={formAction === "ADD" ? handleCreatePost : handleUpdatePost}>
          <Box sx={style} className="modal-theme">
            <div
              className="text-center modalIcon col-12 create-post-title-box"
              onClick={() => {
                handleClose();
              }}>
              <h2>{`${formAction == "ADD" ? "Create" : "Update"} post`}</h2>
              <hr />
              <span>x</span>
            </div>
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <div className="post-creator">
                  <Avatar
                    src={s3baseUrl + userInfo?.profile_image}
                    alt="photoURL">
                    {userInfo?.last_name
                      ? userInfo?.first_name + " " + userInfo.last_name
                      : userInfo?.first_name}
                  </Avatar>
                  <div className="creator-name ps-2 ">
                    <h3>{userInfo?.first_name + " " + userInfo?.last_name}</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 position-relative">
              <InputBase
                inputRef={inputRef}
                style={{
                  color: "white",
                  width: "100%",
                  whiteSpace: "wrap",
                  paddingRight: "45px",
                }}
                sx={{ ml: 1, flex: 1 }}
                placeholder="What's on your mind?"
                inputProps={{
                  "aria-label": "What's on your mind?",
                }}
                multiline
                minRows="2"
                maxRows="6"
                name="description"
                value={inputs.description}
                onChange={handleChange}
                autoFocus
              />
              <GeneralMenuPopup
                use_field="description"
                setInput={setInputs}
                inputRef={inputRef}
              />
            </div>
            {feedType === "video" && (
              <div className="col-12 mt-3 video-url-field search-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video URL"
                  name="VideoUrl"
                  value={inputs.VideoUrl}
                  onChange={handleChange}
                />
                <span
                  className="video-url-cross"
                  onClick={() => {
                    setFeedType("general");
                  }}>
                  x
                </span>
              </div>
            )}

            {feedType === "image" && (
              <div className="col-md-12 mt-2">
                <div className="row">
                  {previews.length > 0 &&
                    previews.map((preview) => {
                      return (
                        <span className="col-3 mb-3 preview create-post-images">
                          <span
                            onClick={() => {
                              handleRemoveImage(preview);
                            }}>
                            x
                          </span>
                          <img
                            src={
                              preview.type == "file"
                                ? URL.createObjectURL(preview.path)
                                : s3baseUrl + preview.thumbnail_1
                            }
                          />
                        </span>
                      );
                    })}
                </div>

                <div className="row">
                  <div className={`col-12`}>
                    <span className="upload-button mt-2 w-100">
                      <input
                        multiple
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        onChange={handleUpload}
                      />
                      <label htmlFor="icon-button-file">
                        <span
                          onClick={() => {
                            setFeedType("general");
                          }}>
                          x
                        </span>
                        <p className="mt-3">
                          Add Photo <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="col-12">
              <div className="video-image-selection">
                <div
                  onClick={() => {
                    setFeedType("image");
                  }}
                  className={`selection-box ${
                    feedType == "image" ? "selected" : ""
                  }`}>
                  <PhotoCameraIcon />
                </div>
                <div
                  onClick={() => {
                    setFeedType("video");
                  }}
                  className={`selection-box ${
                    feedType == "video" ? "selected" : ""
                  }`}>
                  <VideocamIcon />
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              {formAction === "ADD" && (
                <button
                  className="small-contained-button post-submit-btn"
                  disabled={isLoading}>
                  {isLoading ? "Posting..." : "Post"}
                </button>
              )}
              {formAction === "EDIT" && (
                <div className="d-flex justify-content-end">
                  <button
                    className="me-2 small-contained-button post-submit-btn"
                    onClick={handleCancelUpdate}>
                    Cancel
                  </button>
                  <button
                    className="small-contained-button post-submit-btn"
                    type="submit"
                    disabled={isLoading}>
                    {isLoading ? "Updating..." : "Update"}
                  </button>
                </div>
              )}
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
