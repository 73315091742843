import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { RecordNotFound } from "../_dashboard/programmes";
function PodsCards({ data, handleNavigate, notFoundtitle }) {
  const { contentSettingData } = useContentSetting();
  console.log(contentSettingData, "contentSettingData");
  return (
    <div className="container">
      {contentSettingData && contentSettingData.pods_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: contentSettingData.pods_description,
          }}></div>
      ) : (
        <h2>Community Rooms</h2>
      )}
      {data.length > 0 ? (
        <div className="row">
          {data.map((value, index) => {
            return (
              <>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4 ">
                  <div
                    className="card mt-4 pods-cards-shadow cursor h-100"
                    onClick={() => handleNavigate(value.room_slug)}>
                    <img
                      src={s3baseUrl + value.room_image.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Pods"
                    />
                    <div className="card-body">
                      <h3 className="h2-heading">{htmlDecode(value.title)}</h3>
                      <p className="programme-card-desc mb-3">
                        {htmlDecode(value.short_description)}
                      </p>
                      <div className="card-button">
                        <p className="pods-active-members">
                          <span>Active Members {value.room_user.length}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <RecordNotFound title={notFoundtitle} />
      )}
    </div>
  );
}

export default PodsCards;
