import { Avatar, AvatarGroup } from "@mui/material";
import React from "react";
import { communityHandScreenshot, communityHeart } from "src/assets";
import { s3baseUrl } from "src/config/config";

export default function ActionsCount({
  handleOpenLikedBox,
  likeCount,
  topLikedUser,
  handleOpenGratitudeBox,
  gratitudeCount,
  topGratitudeUser,
}) {
  return (
    <div className="my-2 comment-icon d-flex justify-content-between clear-both">
      <div className="svg-color mt-1 comment-icons">
        <div
          className="likes-post d-flex ps-0 ps-md-2"
          onClick={handleOpenLikedBox}>
          {likeCount > 0 && (
            <>
              <img src={communityHeart} alt="" />
              &nbsp;
              {topLikedUser.length > 0 && (
                <AvatarGroup max={2}>
                  {topLikedUser &&
                    topLikedUser.map((liked_user, index) => {
                      return (
                        <Avatar
                          alt={liked_user.user_info_action_by.name}
                          src={
                            s3baseUrl +
                            liked_user.user_info_action_by.profile_image
                          }
                          key={index}
                        />
                      );
                    })}
                </AvatarGroup>
              )}
              {/* {likeCount > 2 && <span>&nbsp;and {likeCount - 2} others</span>} */}
            </>
          )}
        </div>
      </div>
      <>
        <div className="mt-1 likes-post">
          <div
            className="likes-post d-flex ps-0 ps-md-2"
            onClick={handleOpenGratitudeBox}>
            {gratitudeCount > 0 && (
              <>
                <img src={communityHandScreenshot} alt="" />
                &nbsp;
                <AvatarGroup max={2}>
                  {topGratitudeUser &&
                    topGratitudeUser.map((gratitude_user, index) => {
                      return (
                        <Avatar
                          src={
                            s3baseUrl +
                            gratitude_user.user_info_action_by.profile_image
                          }
                          key={index}>
                          {gratitude_user.user_info_action_by.name[0]}
                        </Avatar>
                      );
                    })}
                </AvatarGroup>
                {gratitudeCount > 2 && (
                  <span>&nbsp;and {gratitudeCount - 2} others</span>
                )}
              </>
            )}
          </div>
        </div>
      </>
    </div>
  );
}
