import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  community_feed_action,
  delete_feed_action,
  get_feeds_detail,
} from "src/DAL/Community/Community";
import { Tooltip } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ShowAllLikes from "src/components/CommunityFeed/ShowAllLikes";
import { ReactVideoPlayer } from "src/components";
import FeedComments from "src/components/CommunityFeed/FeedComments";
import CustomPopover from "src/components/CustomPopover";
import CustomConfirmation from "src/components/CustomConfirmation";
import CreatePost from "./CreatePost";
import UserInfoCard from "src/components/CommunityFeed/UserInfoCard";
import ActionsCount from "src/components/CommunityFeed/ActionsCount";
import HandleAction from "src/components/CommunityFeed/HandleAction";
import FeedImageSlider from "src/components/CommunityFeed/FeedImageSlider";

export default function FeedDetailPopup({
  handleCloseDetailBox,
  post_id,
  feed,
  getFeedsListingOnAddFeed,
  handleSuccessDelete,
  handleSuccessUpdate,
  feeds_type,
  openDetailModal,
  selectedImageIndex,
  feedsList,
  fromNotifications,
}) {
  const settings = useContentSetting();
  const { socket } = useContentSetting();

  const [showTab, setShowTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [feedDetail, setFeedDetail] = useState(feed);
  const [isLiked, setIsLiked] = useState(feed.is_liked);
  const [isGratituded, setIsGratituded] = useState(feed.is_gratitude);
  const [topLikedUser, setTopLikedUser] = useState(feed.top_liked_user);
  const [feedType, setFeedType] = useState(feed.feed_type);
  const [topGratitudeUser, setTopGratitudeUser] = useState(
    feed.top_gratitude_user
  );
  const [likeCount, setLikeCount] = useState(feed.like_count);
  const [gratitudeCount, setGratitudeCount] = useState(feed.gratitude_count);
  const [formAction, setFormAction] = useState("EDIT");
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [feedId, setFeedId] = useState("");
  const [isShowMore, setIsShowMore] = useState(true);
  const [totalCommentCount, setTotalCommentCount] = useState(0);
  const [coinsCount, setCoinsCount] = useState(feed.coins_count);
  const [openDelete, setOpenDelete] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openPin, setOpenPin] = useState(false);

  //Changing tab values
  const getFeedsDeteil = async () => {
    const result = await get_feeds_detail(post_id);
    if (result.code === 200) {
      setFeedDetail(result.feeds);
      setCoinsCount(result.feeds.coins_count);
      setIsLiked(result.feeds.is_liked);
      setIsGratituded(result.feeds.is_gratitude);
      setTopLikedUser(result.feeds.top_liked_user);
      setLikeCount(result.feeds.like_count);
      setTopGratitudeUser(result.feeds.top_gratitude_user);
      setGratitudeCount(result.feeds.gratitude_count);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleOpenLikedBox = () => {
    setOpenLikesModal(true);
    setShowTab(1);
  };

  const handleOpenGratitudeBox = () => {
    setOpenLikesModal(true);
    setShowTab(2);
  };

  const handleCloseSimpleBox = () => {
    setOpenLikesModal(false);
    setShowTab();
  };

  const handleFeedAction = async (id, action) => {
    if (action === "feedlike") {
      setIsLiked(true);
    } else if (action === "feedunlike") {
      setIsLiked(false);
    } else if (action === "gratitude") {
      setIsGratituded(true);
    }
    const formData = new FormData();
    formData.append("action", action);
    formData.append("feed", id);
    const result = await community_feed_action(formData);
    if (result.code === 200) {
      if (action === "feedlike" || action === "feedunlike") {
        setTopLikedUser(result.action_response.top_liked_user);
        setLikeCount(result.action_response.like_count);
        setCoinsCount(result.action_response.coins_count);
      } else if (action === "gratitude") {
        setTopGratitudeUser(result.action_response.top_gratitude_user);
        setGratitudeCount(result.action_response.gratitude_count);
        setCoinsCount(result.action_response.coins_count);
      }
      const socketData = {
        action,
        feed_id: id,
        token: localStorage.getItem("token"),
        creator_id: result.action_response.creator_id,
        action_by: result.action_response.sender,
        action_response: result.action_response,
      };

      socket.emit("feed_room_action_event", socketData);
    } else {
      if (action === "feedlike") {
        setIsLiked(false);
      } else if (action === "feedunlike") {
        setIsLiked(true);
      } else if (action === "gratitude") {
        setIsGratituded(false);
      }
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleFeedEdit = (selected_feed) => {
    setFormAction("EDIT");
    setOpenCreateModal(true);
  };

  const handleAgreePin = (value) => {
    setOpenPin(true);
  };

  const handleAgreeDelete = (value) => {
    setFeedId(value._id);
    setOpenDelete(true);
  };

  const handleAgree = async () => {
    setOpenDelete(false);
    const result = await delete_feed_action(feedId);
    if (result.code === 200) {
      enqueueSnackbar("Post deleted successfully", { variant: "success" });
      setOpenDelete(false);

      handleSuccessDelete(feed);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  let MENU_OPTIONS = [];

  if (feedDetail?.is_self) {
    MENU_OPTIONS.push(
      {
        label: "Edit",
        icon: "akar-icons:edit",
        handleClick: handleFeedEdit,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleAgreeDelete,
      }
    );
  }

  useEffect(() => {
    getFeedsDeteil();
  }, [post_id]);

  useEffect(() => {
    // for close on escape
    const is_event_path = window.location.pathname.includes("live-events");

    if (!is_event_path) {
      window.history.replaceState(null, null, `/feed-detail/${post_id}`);
    }

    window.onkeyup = function (event) {
      if (event.keyCode == 27) {
        handleCloseDetailBox();
      }
    };
    return () => {
      if (!is_event_path) {
        window.history.replaceState(null, null, `/community-news-feed`);
      }
    };
  }, []);

  // const update_feed_likes_by_socket = (data) => {
  //   setLikeCount(data.action_response.like_count);
  //   setCoinsCount(data.action_response.coins_count);
  //   setTopLikedUser(data.action_response.top_liked_user);
  //   if (settings.userInfo._id == data.action_by) {
  //     setIsLiked(data.action_response.is_liked);
  //   }
  // };

  // const update_feed_gratitude_by_socket = (data) => {
  //   setTopGratitudeUser(data.action_response.top_gratitude_user);
  //   setGratitudeCount(data.action_response.gratitude_count);
  //   setCoinsCount(data.action_response.coins_count);
  //   if (settings.userInfo._id == data.action_by) {
  //     setIsGratituded(data.action_response.is_gratitude);
  //   }
  // };

  // useEffect(() => {
  //   socket.emit("live_event_room", "live_feed_room");
  //   socket.on("live_feed_room_reciever", (data) => {
  //     if (data.action === "feedlike" || data.action === "feedunlike") {
  //       update_feed_likes_by_socket(data);
  //     } else if (data.action === "gratitude") {
  //       update_feed_gratitude_by_socket(data);
  //     }
  //   });

  //   return () => {
  //     // socket.off("live_feed_room_reciever");
  //   };
  // }, []);

  // useEffect(() => {
  //   socket.on("end_stream_event_listener", (payload) => {
  //     if (payload.feed_id === post_id) {
  //       enqueueSnackbar("Stream ended.", { variant: "info" });
  //       handleCloseDetailBox();
  //     }
  //   });

  //   return () => {
  //     socket.off("end_stream_event_listener");
  //   };
  // }, [post_id]);

  const getImages = (data) => {
    let galleryArray = [];
    let galleryObject = {};
    data.map((gallery, index) => {
      galleryObject = {
        original: s3baseUrl + gallery.thumbnail_1,
        thumbnail: s3baseUrl + gallery.thumbnail_1,
      };
      galleryArray.push(galleryObject);
    });
    return galleryArray;
  };

  return (
    <>
      <div className="row post-detail-box">
        {feedDetail?.feed_type === "general" ? (
          <div
            className={`${
              fromNotifications ? "col-10 col-md-10" : "col-12 col-md-6"
            }  mt-5 mt-md-0 mx-auto profile-cards p-3 mb-3 main-section`}>
            <div className="d-flex w-100 justify-content-between">
              <UserInfoCard
                profile_image={
                  s3baseUrl + feedDetail?.action_info?.profile_image
                }
                user_name={htmlDecode(feedDetail?.action_info?.name)}
                avatar_char={feedDetail?.action_info?.name[0]}
                date={feedDetail?.createdAt}
                activity_type={null}
              />

              {/* <div className="poster-logo text-end">
                  <img src={portalFrontInteractive} alt="" />
                </div> */}
              {
                <div className="poster-logo text-end">
                  {feeds_type == "general" ? (
                    <span className="d-flex feed-level">
                      {feedDetail?.is_self && MENU_OPTIONS.length > 0 && (
                        <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                      )}
                    </span>
                  ) : (
                    <span className="d-flex feed-level">
                      {feedDetail?.is_self && MENU_OPTIONS.length > 0 && (
                        <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                      )}
                    </span>
                  )}
                </div>
              }
            </div>

            <div className="mt-2 post-description">
              {/* {feedDetail?.description.length > LONG_TEXT_LIMIT ? (
                isShowMore ? (
                  <>
                    <p
                      onClick={() => handlePostDetail(feedDetail?._id)}
                      dangerouslySetInnerHTML={{
                        __html: urlify(
                          get_short_string(
                            feedDetail?.description,
                            LONG_TEXT_LIMIT
                          )
                        ),
                      }}></p>
                    <span
                      className="ms-2"
                      onClick={() => {
                        setIsShowMore(false);
                      }}>
                      See More
                    </span>
                  </>
                ) : (
                  <>
                    <p
                      onClick={() => handlePostDetail(feedDetail?._id)}
                      dangerouslySetInnerHTML={{
                        __html: urlify(feedDetail?.description),
                      }}></p>
                    <span
                      className="ms-2"
                      onClick={() => {
                        setIsShowMore(true);
                      }}>
                      See Less
                    </span>
                  </>
                )
              ) : (
                <p
                  onClick={() => handlePostDetail(feedDetail?._id)}
                  dangerouslySetInnerHTML={{
                    __html: feedDetail?.description,
                  }}></p>
              )} */}
              <p
                onClick={() => handlePostDetail(feedDetail?._id)}
                dangerouslySetInnerHTML={{
                  __html: feedDetail?.description,
                }}></p>
            </div>

            <ActionsCount
              handleOpenLikedBox={handleOpenLikedBox}
              likeCount={likeCount}
              topLikedUser={topLikedUser}
              handleOpenGratitudeBox={handleOpenGratitudeBox}
              gratitudeCount={gratitudeCount}
              topGratitudeUser={topGratitudeUser}
              coinsCount={coinsCount}
              feeds_type={feeds_type}
            />
            <hr />
            <HandleAction
              isLiked={isLiked}
              handleFeedAction={handleFeedAction}
              feeds_type={feeds_type}
              setShowAddComment={() => {}}
              isGratituded={isGratituded}
              feed_id={post_id}
            />
            <hr />
            <FeedComments
              is_popup={openDetailModal}
              feed_id={post_id}
              getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
              setTotalCommentCount={setTotalCommentCount}
              feedsList={feedsList}
            />
          </div>
        ) : (
          <>
            {feedDetail.feed_type === "image" &&
              feedDetail.feed_images &&
              feedDetail.feed_images.length > 0 && (
                <div className="col-12 col-md-8 post-detail-image">
                  <FeedImageSlider
                    data={getImages(feedDetail.feed_images)}
                    selectedIndex={selectedImageIndex}
                  />
                </div>
              )}
            {feedDetail?.feed_type === "video" && feedDetail?.video_url && (
              <div className="col-12 col-md-8 post-detail-image">
                <div
                  className={`${
                    fromNotifications ? "feed-video-page" : "feed-video"
                  } mx-5`}>
                  <ReactVideoPlayer
                    url={feedDetail?.video_url}
                    playingVideo={true}
                  />
                </div>
              </div>
            )}

            <div
              className={`col-12 col-md-4 profile-cards p-4 p-lg-3 mb-3 main-section mx-auto ${
                openDetailModal ? "popup-detail-box" : "detail-page-box"
              }`}>
              <div className="d-flex w-100 justify-content-between">
                <UserInfoCard
                  profile_image={
                    s3baseUrl + feedDetail?.action_info?.profile_image
                  }
                  user_name={htmlDecode(feedDetail?.action_info?.name)}
                  avatar_char={feedDetail?.action_info?.name[0]}
                  date={feedDetail?.createdAt}
                  activity_type={null}
                />

                {feedDetail?.is_self && MENU_OPTIONS.length > 0 && (
                  <div className="poster-logo text-end">
                    <CustomPopover menu={MENU_OPTIONS} data={feedDetail} />
                  </div>
                )}
              </div>
              <div className="mt-2 post-description">
                {/* {feedDetail?.description.length > 200 ? (
                  isShowMore ? (
                    <>
                      <p
                        onClick={() => handlePostDetail(feedDetail?._id)}
                        dangerouslySetInnerHTML={{
                          __html: urlify(
                            get_short_string(
                              feedDetail?.description,
                              LONG_TEXT_LIMIT
                            )
                          ),
                        }}></p>
                      <span
                        className="ms-2"
                        onClick={() => {
                          setIsShowMore(false);
                        }}>
                        See More
                      </span>
                    </>
                  ) : (
                    <>
                      <p
                        onClick={() => handlePostDetail(feedDetail?._id)}
                        dangerouslySetInnerHTML={{
                          __html: urlify(feedDetail?.description),
                        }}></p>
                      <span
                        className="ms-2"
                        onClick={() => {
                          setIsShowMore(true);
                        }}>
                        See Less
                      </span>
                    </>
                  )
                ) : (
                  <p
                    onClick={() => handlePostDetail(feedDetail?._id)}
                    dangerouslySetInnerHTML={{
                      __html: urlify(feedDetail?.description),
                    }}></p>
                )} */}
                <p
                  onClick={() => handlePostDetail(feedDetail?._id)}
                  dangerouslySetInnerHTML={{
                    __html: feedDetail?.description,
                  }}></p>
              </div>

              <ActionsCount
                handleOpenLikedBox={handleOpenLikedBox}
                likeCount={likeCount}
                topLikedUser={topLikedUser}
                handleOpenGratitudeBox={handleOpenGratitudeBox}
                gratitudeCount={gratitudeCount}
                topGratitudeUser={topGratitudeUser}
                coinsCount={coinsCount}
                feeds_type={feeds_type}
              />
              <hr />
              <HandleAction
                isLiked={isLiked}
                handleFeedAction={handleFeedAction}
                feeds_type={feeds_type}
                setShowAddComment={() => {}}
                isGratituded={isGratituded}
                feed_id={post_id}
              />
              <hr />
              <FeedComments
                is_popup={openDetailModal}
                feed_id={post_id}
                getFeedsListingOnAddFeed={getFeedsListingOnAddFeed}
                setTotalCommentCount={setTotalCommentCount}
              />
            </div>
          </>
        )}

        {openLikesModal && (
          <ShowAllLikes
            openLikesModal={openLikesModal}
            setOpenLikesModal={setOpenLikesModal}
            handleCloseSimpleBox={handleCloseSimpleBox}
            showTab={showTab}
            post_id={post_id}
            feeds_type={feeds_type}
          />
        )}
        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete this post?"}
          handleAgree={handleAgree}
        />
      </div>

      {setOpenCreateModal && (
        <CreatePost
          setFeedType={setFeedType}
          feedType={feedType}
          openCreateModal={openCreateModal}
          setOpenCreateModal={setOpenCreateModal}
          getFeedsListing={getFeedsListingOnAddFeed}
          userInfo={settings.userInfo}
          selectedFeed={feed}
          formAction={formAction}
          setFormAction={setFormAction}
          handleSuccessUpdate={handleSuccessUpdate}
          feeds_type={feeds_type}
        />
      )}
    </>
  );
}
