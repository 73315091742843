import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { format_date_with_timezone } from "src/utils/formatTime";
import RequestsList from "./RequestsList";
import { get_site_setting, PaymentRequestsList } from "src/DAL/Payment/Payment";
import { RecordNotFound } from "../_dashboard/programmes";
const ProgrammesImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const billing1 = [
  {
    id: 1,
    title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    date: "Jan, 17 2022",
    total_amount: "$9000",
    InitialDepositAmount: "$9000",
    MonthlyInstallments: "$9000",
    InstallmentsPlan: "10 months",
    request_type: "onetime",
    payment_request_type: "pending",
  },
  {
    id: 2,
    title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    date: "Jan, 17 2022",
    total_amount: "$9000",
    InitialDepositAmount: "$9000",
    MonthlyInstallments: "$9000",
    InstallmentsPlan: "5 months",
    request_type: "pending",
    payment_request_type: "pending",
    request_type: "recurring",
  },
];

const billing = [
  {
    id: 1,
    title: "Thinking into Results - 6 Month Mentorship - Payment Plan",
    date: "Jan, 17 2022",
    total_amount: "$9000",
    InitialDepositAmount: "$9000",
    MonthlyInstallments: "$9000",
    InstallmentsPlan: "10 months",
    request_type: "onetime",
  },
];

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function PaymentRequests() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequest, setPendingRequest] = useState([]);
  const [activeRequest, setActiveRequest] = useState([]);
  const [value, setValue] = useState(0);
  //Changing tab values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Getting lesson detail
  const PaymentRequestsListing = async () => {
    const result = await PaymentRequestsList();
    if (result.code === 200) {
      let all_requests = [];
      result.pending_payment_request.map((request, index) => {
        all_requests.push({
          ...request,
          payment_request_type: "pending",
        });
      });
      setPendingRequest(all_requests);
      setActiveRequest(result.active_payment_request);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/dashboard`);
    }
  };

  useEffect(() => {
    PaymentRequestsListing();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <h2>Payment Requests</h2>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12 section-space">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                // allowScrollButtonsMobile="auto"
                aria-label="scrollable auto tabs example">
                <Tab label="Pending Requests" {...a11yProps(0)} />
                <Tab label="Accepted Requests" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="row">
                {pendingRequest.length > 0 ? (
                  <div className="col-12 mt-3">
                    <RequestsList data={pendingRequest} />
                  </div>
                ) : (
                  <RecordNotFound title="Pending Requests" />
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="row">
                {activeRequest.length > 0 ? (
                  <div className="col-12 mt-3">
                    <RequestsList data={activeRequest} />
                  </div>
                ) : (
                  <RecordNotFound title="Accepted Requests" />
                )}
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default PaymentRequests;
