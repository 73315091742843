import React, { useEffect, useState } from "react";
import { IconButton, CircularProgress } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useSnackbar } from "notistack";
import { s3baseUrl } from "src/config/config";
import AddPhotos from "./AddPhotos";
import CustomConfirmation from "src/components/CustomConfirmation";
import { RecordNotFound } from "../programmes";
import { DeleteGalleryImage } from "src/DAL/SelfImage/SelfImage";
import { SelfImageListApi } from "src/DAL/JournalPrompts/JournalPrompts";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const JournalPromptManagePhotos = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [goalStatementGallery, setGoalStatementGallery] = useState([]);

  const getGoalStatementList = async () => {
    const result = await SelfImageListApi();
    if (result.code === 200) {
      let galleryArray = [];
      let galleryObject = {};
      result?.self_image_gallery.map((gallery, index) => {
        galleryArray.push(gallery);
      });
      setGoalStatementGallery(galleryArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const openDrawer = () => {
    setDrawerState(true);
  };
  const closeDrawer = () => {
    setDrawerState(false);
  };

  const handleAgreeDelete = (image_path) => {
    setImagePath(image_path);
    setOpenDelete(true);
  };

  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const setImageObject = {
      self_image_path: imagePath,
      module_type: "journal_prompt",
    };
    const result = await DeleteGalleryImage(JSON.stringify(setImageObject));
    if (result.code === 200) {
      getGoalStatementList();
      setIsLoading(false);
      enqueueSnackbar("Gallery image deleted successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGoalStatementList();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-7">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="col-5">
          <button
            className="small-contained-button float-end mt-1"
            onClick={openDrawer}>
            Add Photos
          </button>
        </div>
      </div>
      <div className="row section-space">
        <div className="col-12">
          <h2>Manage Photos</h2>
        </div>
        <div className="col-12">
          <div className="row photo-row">
            {goalStatementGallery.length > 0 ? (
              goalStatementGallery.map((data, i) => {
                return (
                  <div className="col-sm-12 col-md-4 col-lg-4 delete-icon">
                    <div className="image-div">
                      <span
                        className="delete-icon-position"
                        onClick={() => handleAgreeDelete(data)}>
                        <div className="cross-icon text-center">x</div>
                      </span>
                      <a href={s3baseUrl + data.thumbnail_1} target="_blank">
                        <img
                          height="200px"
                          src={s3baseUrl + data.thumbnail_2}
                          alt="Lights"
                          style={{ width: "100%" }}
                        />
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <RecordNotFound title="Images" />
            )}
          </div>
        </div>
      </div>
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={openDrawer}
        onCloseDrawer={closeDrawer}
        pageTitle="Add Photo"
        componentToPassDown={
          <AddPhotos
            setIsLoading={setIsLoading}
            onCloseDrawer={closeDrawer}
            dataList={getGoalStatementList}
          />
        }
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete this image?"}
        handleAgree={handleDelete}
      />
    </div>
  );
};

export default JournalPromptManagePhotos;
