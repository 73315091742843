import { invokeApi } from "../../bl_libs/invokeApi";

export const memberDetail = async () => {
  const requestObj = {
    path: `api/member/profile`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const timeZones = async (data) => {
  const requestObj = {
    path: `app/time_zone_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const ProjectInfoApi = async () => {
  const requestObj = {
    path: `api/member_init`,
    method: "GET",
    headers: {
      // "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const EditProfileApi = async (data) => {
  const requestObj = {
    path: `api/member/edit_member_profile`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
