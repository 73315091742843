import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "../../config/config";
import { ReactVideoDurationPlayer } from "src/components";
import { useSnackbar } from "notistack";
import {
  ProgramRecordingDetail,
  LessonRecordingDetail,
} from "../../DAL/Programmes/Programmes";
import { makeStyles } from "@mui/styles";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { Icon } from "@iconify/react";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function ProgrammRecordingDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [recordingData, seRecordingData] = React.useState({});
  const [videoProgressData, setVideoProgressData] = useState([]);
  const { handleVideoList } = useContentSetting();
  const handleNavigate = () => {
    navigate(-1);
  };
  const handleNavigateLink = (value) => {
    window.open(value, "_blank");
  };
  const getRecordingDetail = async () => {
    setIsLoading(true);
    let result = {};
    if (location.pathname.includes("lessons-recordings") == true) {
      result = await LessonRecordingDetail(params.recording_slug);
    } else {
      result = await ProgramRecordingDetail(params.recording_slug);
    }
    if (location.pathname.includes("/lessons-recordings/")) {
      console.log("for lesson recordings");
      localStorage.setItem("video_type", "lesson_recording");
    } else {
      console.log("from program recordings");
      localStorage.setItem("video_type", "replay_library");
    }

    if (result.code === 200) {
      let data = [
        {
          recording_id: result.recording?._id,
          video_duration: result.recording?.video_duration,
          total_video_duration: result.recording?.total_video_duration,
          is_complete: result.recording?.is_complete,
        },
      ];
      setVideoProgressData(data);
      handleVideoList(data);
      localStorage.setItem("video_list", JSON.stringify(data));

      seRecordingData(result.recording);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programs`);
    }
  };
  useEffect(() => {
    getRecordingDetail();
  }, [params.recording_slug]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => handleNavigate()}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(recordingData.title)}
          </h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          <ReactVideoDurationPlayer
            url={recordingData.video_url}
            type={localStorage.getItem("video_type")}
            value={recordingData}
            setVideoProgressData={setVideoProgressData}
            videoProgressData={videoProgressData}
          />
        </div>
        <div className="col-12 text-end">
          {recordingData.is_complete ? (
            <Icon
              icon="mdi:tick-circle"
              width={30}
              height={30}
              color="primary"
            />
          ) : (
            // <Icon
            //   icon="clarity:clock-solid"
            //   width={30}
            //   height={30}
            //   color="primary"
            // />
            <></>
          )}
        </div>
        {recordingData.audio_recording && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + recordingData.audio_recording}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space text-end">
          {recordingData?.button_text && recordingData?.button_link && (
            <Chip
              label={htmlDecode(recordingData?.button_text)}
              color="primary"
              className="me-1"
              variant="outlined"
              onClick={() => handleNavigateLink(recordingData?.button_link)}
            />
          )}
        </div>
        <div className="col-12 section-space">
          <p>{htmlDecode(recordingData.short_description)}</p>
        </div>
      </div>
    </div>
  );
}

export default ProgrammRecordingDetail;
